













































import { Component, Vue } from "vue-property-decorator";
import AppButton from "@/components/core/AppButton.vue";
import AppInput from "@/components/core/AppInput.vue";
import AppLabel from "@/components/core/AppLabel.vue";
import AppWidget from "@/components/core/AppWidget.vue";
import { createErrorNotification, Notification } from "@/utils/notification";
import { changeKnownPassword } from "@/utils/authentication";

@Component({
  components: { AppButton, AppInput, AppLabel, AppWidget },
})
export default class AppChangePasswordWidget extends Vue {
  currentPassword = "";
  newPassword = "";
  notification: Notification | null = null;
  saving = false;

  /**
   * Changes the User's password
   */
  async changePassword(): Promise<void> {
    this.saving = true;
    this.notification = null;

    try {
      if (!this.currentPassword || !this.newPassword)
        throw new Error("Missing data.");

      await changeKnownPassword(this.currentPassword, this.newPassword);
      this.currentPassword = this.newPassword = "";
    } catch (err) {
      this.notification = createErrorNotification(
        "Your password was not changed",
        err.message
      );
    } finally {
      this.saving = false;
    }
  }
}
