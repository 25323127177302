









































import { Component, Vue } from "vue-property-decorator";
import AppAddressWidget from "@/components/widgets/AppAddressWidget.vue";
import AppButton from "@/components/core/AppButton.vue";
import AppChangePasswordWidget from "@/components/widgets/AppChangePasswordWidget.vue";
import AppUpdateUserWidget from "@/components/widgets/AppUpdateUserWidget.vue";
import TheHeader from "@/components/TheHeader.vue";
import TheMain from "@/components/TheMain.vue";
import { currentUser } from "@/utils/authentication";

@Component({
  components: {
    AppButton,
    AppChangePasswordWidget,
    AppAddressWidget,
    AppUpdateUserWidget,
    TheHeader,
    TheMain,
  },
})
export default class Profile extends Vue {
  user: pro.User | null = null;

  mounted() {
    this.user = currentUser();
  }
}
