





















































import { Component, Prop, Vue } from "vue-property-decorator";
import AppButton from "@/components/core/AppButton.vue";
import AppCountdown from "@/components/core/AppCountdown.vue";
import AppInput from "@/components/core/AppInput.vue";
import AppLabel from "@/components/core/AppLabel.vue";
import AppWidget from "@/components/core/AppWidget.vue";
import { createErrorNotification, Notification } from "@/utils/notification";
import { updateUser } from "@/utils/api";

@Component({
  components: {
    AppButton,
    AppCountdown,
    AppInput,
    AppLabel,
    AppWidget,
  },
})
export default class AppUpdateUserWidget extends Vue {
  @Prop({ type: Object, required: true })
  readonly user!: pro.User;

  notification: Notification | null = null;
  saving = false;

  /**
   * Updates a user's contact information
   */
  async updateUser(): Promise<void> {
    this.saving = true;
    this.notification = null;

    try {
      if (!this.user) throw new Error("Missing user data.");
      await updateUser(this.user);
    } catch (err) {
      this.notification = createErrorNotification(
        "Unable to save your changes.",
        err.message
      );
    } finally {
      this.saving = false;
    }
  }

  /**
   * Checks whether the required data has been updated
   */
  readyToUpdate(): boolean {
    const user = this.user;
    if (!user || !user.fullName || !user.emailAddress) return false;
    return true;
  }
}
